import React, { useState } from "react";
import styled, {css} from "styled-components";
import { ButtonBase} from '../Layout/pageParts';
import {Colours} from '../Layout/colours';

const Container = styled.div`
    display:flex;
    align-items: center;
`

const QuantityContainer = styled.div`
    display: flex;
    border: 1px solid ${Colours.Granite};
    padding:0px;    
    min-width: 120px;
    height: 44px;
    align-items: center;
    justify-content: center;
`
const Quantity = styled.div`
    margin-left:10px;
    margin-right:10px;
`;

const QuantityButton = styled(ButtonBase)`    
    ${props => props.disabled && css`    
    opacity: 0.65; 
    cursor: not-allowed;
  `}  
`;

const AddToCartButton = styled(ButtonBase)`
    background-color: black;    
    color: white;
    margin: 10px;
    min-width: 110px;
    ${props => props.disabled && css`
        opacity: 0.5;    
        transition: all 300ms ease-in;
  `} 

`;

const defaultButtonText = "Add to cart";
const addedButtonText = "Added";

const AddToCart = ({onAddToCart}) => {

    const minQuantity = 1;
    const maxQuantity = 20;
    const [quantity, setQuantity] = useState(minQuantity);
    const [buttonText, setButtonText] = useState(() => defaultButtonText)
    
    const TryReduceQuantity = () => {
        if(quantity > minQuantity)
            setQuantity(quantity - 1);
    }

    const TryIncreaseQuantity = () => {
        if(quantity < maxQuantity)
            setQuantity(quantity + 1);
    }    

    const updateCart = () => {        
        setButtonText(addedButtonText);
        onAddToCart(quantity);        
        setTimeout(()=>{
            setButtonText(defaultButtonText);
        },500)
    }

    return (
    <Container>
        <QuantityContainer>
            <QuantityButton disabled={quantity <= minQuantity} onClick={()=> TryReduceQuantity()}>-</QuantityButton>
            <Quantity>{quantity}</Quantity>
            <QuantityButton disabled={quantity >= maxQuantity} onClick={()=> TryIncreaseQuantity()}>+</QuantityButton>
        </QuantityContainer>
    <AddToCartButton disabled={buttonText === addedButtonText} onClick={() => updateCart()}>{buttonText}</AddToCartButton>
    </Container>
    )
}

export default AddToCart;