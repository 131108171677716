import React, { useContext } from "react"
import { useLocation } from "@reach/router"
import queryString from 'query-string'  
import Img from "gatsby-image";
import styled from "styled-components"
import { Colours } from '../Layout/colours';
import { Heading, Title } from '../Layout/pageParts';
import GetProducts from '../../store/api/reapShop';
import AddToCart from '../Shop/addToCart';
import {CartContext} from '../../store/cartState';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;   
    width:330px;
    padding-left:10px;
    padding-right:10px;    
    margin-bottom:20px;
    
`;

const TitleCompact = styled(Title)`
   margin-bottom: 0px;
   margin-top: 0px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    width: 300px;
    padding-left:10px;
    padding-right:10px;
    margin-top:10px;
    margin-bottom:0px;
`;

const ImageSmall = styled.div`    
    margin: 20px 20px 0px 0px;  
    width:350px;   
    height:270px;   
    align-self: baseline; 

    @media screen and (max-width: 600px){
        
      align-self: center;
      margin: auto; 
    }
`;

const CenteredContaner = styled.div`   
    display: flex;
    flex-wrap:wrap; 
    max-width:600px;

    @media screen and (max-width: 600px){
     flex-direction: column;
    }
`;

const Details = styled.div`   
    max-width:600px;
    
`;

const Line = styled.hr` 
    flex:1;          
    vertical-align:bottom;
    border: none;
    height: 1px;    
    margin-bottom: 15px;
    background: ${Colours.Granite};
`;

const getDataFromCatalog = (location) => {
    var qs = queryString.parse(location.search);     
    return GetProducts().find(p => p.name === qs.name);
}

const ProductDetails = (props) => {
    let location = useLocation();    
    const { addToCart} = useContext(CartContext);  
    const {id, name, image, price, originalPrice, ingredients, description, application} = props.name === undefined ? getDataFromCatalog(location) : props;
    const applicatons = application.map((a,i) => <Container key={i}><TitleCompact>{a}</TitleCompact></Container>  )
    
  return (
    <CenteredContaner>
            <ImageSmall>                
                <Img alt={`${name} oil`} fluid={image.childImageSharp.fluid} style={{ width: "100%", height: "100%" }} />                
            </ImageSmall>
            <Details>  
                <TitleContainer>
                    <Heading>{name}</Heading>                                    
                    {originalPrice && <Heading><s>£{originalPrice}</s></Heading> }
                    <Heading>£{price}</Heading> 
                </TitleContainer>
                <Line/>
                <Container>                        
                    <Title>{description}</Title>                  
                </Container>                 
                <Container>   
                    <Title>{ingredients}</Title>          
                </Container>  
                {applicatons} 
                <Container>                        
                    <AddToCart onAddToCart={(quantity) => {
                    console.log(`Adding ${quantity} to cart`);
                    addToCart({id, name, image, price, originalPrice, ingredients, description, application}, quantity);
                }}/>
                </Container>                
            </Details>            
    </CenteredContaner>  
  )

}

export default ProductDetails
