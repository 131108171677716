import React from "react";
import Layout from "../components/Layout/layout"
import ProductDetails from "../components/Products/productDetails";

const ProductDetailsPage = (props) => { 
  
  if(props.location.state !== undefined){
    return(
    <Layout>      
      <ProductDetails {...props.location.state} />         
    </Layout>   ) 
  }
  return <div></div>
 };

export default ProductDetailsPage;